@import '~bulma/sass/utilities/_all.sass';

$primary: #1DBF94;
$link: $primary;
$text: $white;
$title-color: $text;
$content-heading-color: $text;

$background: #000005;
$footer-background-color: $background;

$brand-font-family: 'Quantum';

$title-weight: $weight-medium;
$size-1: 3.5rem;

$height-break-point: 900px;

// Navbar variables
$navbar-background-color: rgba(0, 0, 0, .37);
$navbar-breakpoint: 0;
