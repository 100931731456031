@import "../../bulma_base.sass";

.failfalcon-section {
  min-height: 100vh;
  padding-bottom: 30vh;
  background-image: url('/images/ff_background_cutout.svg'), url('/images/ff_background.jpg');
  background-repeat: no-repeat;
  background-size: 100%, auto;
  background-position-x: center;
  background-position-y: 100px;

  @media only screen and (max-width: $tablet) {
    margin-top: 20vh; }

  .logo {
    position: relative;
    height: 0;

    img {
      position: absolute;
      width: 500px;
      max-height: 500px;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1; } }

  .container {
    max-width: 800px;

    .card {
      background-color: rgba(23, 0, 0, .6);

      .card-image figure div {
        background-size: 70px, cover;
        background-repeat: repeat-x, no-repeat;
        background-position: top, center; }

      .image div {
        width: 100%;
        height: 100%;
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        display: inline-block;
        max-width: 100%;
        background-size: cover;
        background-position: center; } } } }
