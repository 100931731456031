@import './bulma_base.sass';

@import '~bulma/bulma.sass';
@import '~@fortawesome/fontawesome-free/css/all.css';

/* Fonts */
@font-face {
  font-family: 'Quantum';
  src: local('Quantum'), url(./fonts/Quantum.otf) format('opentype'); }

@font-face {
  font-family: 'Impact';
  src: local('Impact'), url(./fonts/Impact.ttf) format('truetypefont'); }


body {
  background: $background;
  background-image: url('/images/header_background.svg');
  background-size: 5000px;
  background-position-x: center;
  background-repeat: no-repeat;

  @media only screen and (max-height: $height-break-point) {
    background-position-y: -4rem; } }

.is-family-brand {
  font-family: $brand-font-family;
  font-weight: 500 !important; }
