@import '../../bulma_base.sass';

.navbar {
  .navbar-menu {
    .navbar-end {
      padding-right: 2rem;
      .navbar-item {
        .social-media-icon {
          color: $grey;
          margin-right: 1rem;
          .fab {
            font-size: 1.5em;
            cursor: pointer;
            text-align: center; } } } } } }
