@import '../../bulma_base.sass';

.banner {
  min-height: 92vh;


  .brand-name {
    margin: 2rem 0 6rem;

    @media only screen and (min-width: $tablet) {
      margin-bottom: 12rem; }
    @media only screen and (max-height: $height-break-point) {
      margin-bottom: 8rem; } }

  .logo-background {
    width: 0;
    height: 0;

    div {
      position: relative; } }

  .logo {
    max-width: 500px;

    display: block;
    float: none;
    margin: auto auto 3rem;

    @media only screen and (min-width: $tablet) {
      float: right;
      margin: 0 3rem 0 0; } }

  .slogan-container {
    display: flex;
    align-items: center;
    font-family: 'Impact';
    letter-spacing: 0.025rem;

    .slogan-content {
      @media only screen and (min-width: $desktop) {
        margin-left: 3rem; } } }

  .brand-name {
    -webkit-user-select: none /* Safari */;
    -moz-user-select: none /* Firefox */;
    -ms-user-select: none /* IE10+/Edge */;
    user-select: none /* Standard */; } }
