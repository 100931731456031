@import '../../bulma_base.sass';

#mc_embed_signup {
    margin-top: 2rem;

    .the-input-field {
        position: absolute;
        left: -5000px;
        margin-bottom: 4rem; }

    @media only screen and (min-width: $desktop) {
        margin-bottom: 0; } }
